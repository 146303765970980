import { useEffect, useRef, useState } from "react";
import Moment from 'react-moment';
import { Spinner, PrimaryButtonOutline, Dialog, ToggleButton, Notification, NotificationConfig } from "../../../Elements";
import {
  useApplicantGoalInfo,
  saveApplicantGoalInfo,
  getBalance,
  deleteApplicantGoalInfo,
  ResendEmail,
} from "../api/getApplicantGoalInfo";
import { Balance, TrackingType } from "../types";
import { GoalForm } from "./GoalForm";
import "bootstrap-icons/font/bootstrap-icons.scss";
import styled from 'styled-components';

import { ApplicantDto, getTheme, getConfigs, HomeSaverOptionsDto } from "../../../config";
import { ReactComponent as Idea } from '../../../assets/images/idea.svg';
import { TurnOffTackingForm } from "./TurnOffTrackingForm";
import { GoalProgress } from "./GoalProgress";

// eslint-disable-next-line import/no-webpack-loader-syntax
import toastifyCss from '!!raw-loader!react-toastify/dist/ReactToastify.css';
import HomeSaverProgramSettings from "./HomeSaverProgramSettings";

const TrackingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  background: #F9FBFF;
  padding: 20px;  /* Adds padding inside the container */
  min-height: 80px;  /* Optional: Sets a minimum height */
`;

const TrackingLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
`;

const InfoIcon = styled.i`
  cursor: pointer;
  color: #000000;
`;

const TooltipContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tooltip = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  bottom: -75px;
  left: -10px;
  background-color: #000;
  color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 9999;
  
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 12px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000000 transparent;
  }
`;

const EditIcon = styled.i`
  cursor: pointer;
  margin-left: auto;  /* This pushes the icon to the far right within a flex container */
`;

// const KeyValueLabel = styled.div`
//   display: flex;
//   flex-direction: column;  /* Stacks the key and value vertically */
//   align-items: flex-start;  /* Aligns text to the left */
//   margin-left: 20px;  /* Adds some left margin for spacing */
// `;

// const Key = styled.span`
//   font-size: medium;
//   color: #9A9B9E;
// `;

// const Value = styled.span`
//   font-size: medium;
//   font-weight: bold; 
// `;

export const ApplicantGoalTracking = () => {
  const applicantInfo = useApplicantGoalInfo(TrackingType.Savings);
  const [modalShow, setModalShow] = useState(false);
  const [turnOffmodalShow, setTurnOffModalShow] = useState(false);
  const [balance, setBalance] = useState<Balance>();
  const [isGoalTrackingEnabled, setEnableGoalTracking] = useState(false);
  //const [isLoanTrackingEnabled, setLoanTrackingEnabled] = useState(false);
  const [tooltipShow, setTooltipShow] = useState(false);
  const [lastSentTooltipShow, setLastSentTooltipShow] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([] as ApplicantDto[]);
  const [isEditMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [resendIsLoading, setResendIsLoading] = useState(false);
  const [deleteApplicants, setDeleteApplicants] = useState([] as ApplicantDto[]);
  const [enabledApplicants, setEnabledApplicants] =  useState([] as ApplicantDto[]);
  const [notEnabledApplicants, setNotEnabledApplicants] =  useState([] as ApplicantDto[]);
  const [homeSaverOptions, setHomeSaverOptions] = useState({} as HomeSaverOptionsDto);
  const [jointViewSelected, setJointViewSelected] = useState(false);

  const notificationRef = useRef<NotificationConfig>(null);

  const theme = getTheme();
  const config = getConfigs();

  useEffect(() => {

    if(config.joinViewFeature && applicantInfo?.data){
      setJointViewSelected(applicantInfo?.data?.jointAccountEnabled ?? false);
    }

    if(config.applicants && config.applicants.length == 1){
      setSelectedApplicants(config.applicants);
      setDeleteApplicants(config.applicants);
    }

    if (config.homeSaverOptions) {
      setHomeSaverOptions(config.homeSaverOptions);
    }

    if (applicantInfo?.data?.status === "Registered" || applicantInfo?.data?.status === "Achieved") {
      getBalance().then((balance) => {
        setBalance(balance);
      });
    }
    
    if(applicantInfo?.data && (applicantInfo?.data?.status === "Registered" || applicantInfo?.data?.status === "Achieved")){
      setEnableGoalTracking(true);
      
      let enabledArr = [];
      let notEnabledArr = [];

      for(var i = 0; i < config.applicants.length; i++){
        var exist = applicantInfo?.data.enabledApplicants.some(x => {
          return x.referenceId == config.applicants[i].applicantId;
        });

        if(exist){
          enabledArr.push(config.applicants[i]);
        }else{
          notEnabledArr.push(config.applicants[i]);
        }
      }

      setEnabledApplicants(enabledArr);
      setNotEnabledApplicants(notEnabledArr);
    }else{
      /*if(applicantInfo?.data && applicantInfo?.data?.status === "LoanTracking"){
        setLoanTrackingEnabled(true);
      }*/

      setEnableGoalTracking(false);
    }
    window.addEventListener('new-applicant', (event) => { applicantInfo.refetch() });
    window.addEventListener('delete-applicant',(event) =>{ applicantInfo.refetch() });

    return () => {
      window.removeEventListener('delete-applicant',(event) =>{ applicantInfo.refetch() });
      window.removeEventListener('new-applicant', (event) => { applicantInfo.refetch() });
    };
  }, [applicantInfo?.data?.status, applicantInfo?.isFetching]);

  useEffect(() => {
    if(config.joinViewFeature){
      if(jointViewSelected){
        setSelectedApplicants(config.applicants);
      }
      else{
        setSelectedApplicants(notEnabledApplicants);
      }
    }
  }, [jointViewSelected]);

  if (applicantInfo.isLoading) {
    return (
      <div className="d-flex justify-content-end">
        <Spinner />
      </div>
    );
  }

  const enableGoalTracking = async () => {
        if(!isGoalTrackingEnabled){
            setModalShow(true);
        }else{
          setTurnOffModalShow(true);
        }
   }

   const editGoalTracking = () => {
      setEditMode(true);
      setModalShow(true);
   }

   const handleApplicantChange = (selectedOption: any[]) => {
      let filteredApplicants = config.applicants.filter(it => {
        return selectedOption.some(x => {
          return x.value == it.applicantId;
        })
      });
      
      setSelectedApplicants(filteredApplicants);
   }

   const handleJointViewChange = (value: boolean) => {
    if(config.joinViewFeature){
      setJointViewSelected(value);
    }
  }
   const handleDeleteApplicantChange = (selectedOption: any[]) => {
    let filteredApplicants = config.applicants.filter(it => {
      return selectedOption.some(x => {
        return x.value == it.applicantId;
      })
    });
    
    setDeleteApplicants(filteredApplicants);
 }

   const closeModal = () => {      
      setModalShow(false);
      if(isEditMode){
        setTimeout(() => {
          setEditMode(false);
        },100);        
      }
   }

   const resendEmail = () => {
      setResendIsLoading(true);
      ResendEmail(selectedApplicants, TrackingType.Savings).then((sentTime: Date) => 
      {
        notificationRef.current?.success("Successfully sent email");
        if(applicantInfo?.data){
          applicantInfo.data.lastEmailSentTime = sentTime;
        }
        setResendIsLoading(false);
      }
      , err => {
        notificationRef.current?.error("Something went wrong");
        setResendIsLoading(false);
      });
   }

   const goalDisabledTooltip = `You can track savings of this lead using open banking and be notified once the savings goal is achieved.
   Once the lead connects their bank account and the track Savings toggle remains ON, $5 plus GST will be charged every month. Switch off the Toggle 
   anytime to stop the tracking and billing for this lead.`;

   const goalEnabledTooltip = `You will be charged $5 plus GST per month once the account it connected by the lead and toggle remains ON.
    Switch the toggle to OFF anytime to stop the billing for the leads.`;

    //Last Sent 12:38 AM on 26 Jul 2023 

    return (
        <>
           <style>{toastifyCss}</style>
            {/* <style>{css}</style> */}
            <div id="applicant-goal-tracking">
                <TrackingContainer>
                    <ToggleButton onChange={enableGoalTracking} checked={isGoalTrackingEnabled}></ToggleButton>
                    <TrackingLabel>Track Savings</TrackingLabel>
                    <TooltipContainer>
                        <InfoIcon tabIndex={0} className="bi bi-info-circle" onClick={() => setTooltipShow(true)} onBlur={() => setTooltipShow(false)}></InfoIcon>
                        <Tooltip show={tooltipShow}>{isGoalTrackingEnabled ? goalDisabledTooltip : goalEnabledTooltip}</Tooltip>
                    </TooltipContainer>
                    {isGoalTrackingEnabled ? 
                      <>
                      {/* <KeyValueLabel>
                          <Key>Goal:</Key>
                          <Value>{balance ? toCurrencyFormat(balance!.goal ).split('.')[0] : "Not provided"}</Value>
                      </KeyValueLabel>
                      <KeyValueLabel>
                          <Key>Saved:</Key>
                          <Value>{balance && balance!.amount ? toCurrencyFormat(balance!.amount).split('.')[0] : "Not provided"}</Value>
                      </KeyValueLabel>
                      <ProgressBar progress1={progress1} progress2={progress2} pointer={pointer} pointerlabel={pointerLabel} style={{ width: '50%',
                          marginLeft: '40px' }}></ProgressBar> */}
                      <GoalProgress balance={balance}></GoalProgress>
                      <EditIcon className="bi bi-pencil-fill" style={{ color: theme.primaryColor }} onClick={editGoalTracking}></EditIcon>
                      </>
                : null }
                </TrackingContainer>
                {(homeSaverOptions.isEnabled && isGoalTrackingEnabled) ?
                  <TrackingContainer>
                    <HomeSaverProgramSettings options={homeSaverOptions} />
                  </TrackingContainer>
                  : null}
                {isGoalTrackingEnabled ? 
                <TrackingContainer>
                  <PrimaryButtonOutline loading={resendIsLoading ? 'true' : 'false'} onClick={resendEmail}>Resend Email/SMS</PrimaryButtonOutline>
                  <TooltipContainer>
                        <InfoIcon tabIndex={1} className="bi bi-info-circle" onClick={() => setLastSentTooltipShow(true)} onBlur={() => setLastSentTooltipShow(false)}></InfoIcon>
                        <Tooltip show={lastSentTooltipShow}>Last Sent{' '} 
                          <Moment format="LT">{applicantInfo?.data?.lastEmailSentTime}</Moment> on{' '} 
                          <Moment format="D MMM YYYY">{applicantInfo?.data?.lastEmailSentTime}</Moment>
                        </Tooltip> 
                    </TooltipContainer>
                </TrackingContainer>  : null }
                <Dialog
                    show={modalShow}
                    fullscreen={false}
                    className="applicant-goal-tracking-modal"
                    onHide={closeModal}
                    title="Track Savings"
                    size="lg"
                    // primaryColor={theme.primaryColor}
                >
                  {isEditMode ?  null : <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FEF7E8', padding: '15px',
                      borderRadius: '5px', marginBottom: '40px' }}>
                   <Idea style={{ width: '100px', height: '100px' }} />
                    <div style={{ marginLeft: '10px', color: '#8E95A7' }}>
                        Set a savings goal target and send an invite to this lead to connect their accounts using
                        open banking in customer portal. You'll be notified instantly once the savings
                        goal target is reached. We will receive daily savings balance in the portal for tracking
                        purpose.
                    </div>
                </div> }
                    <GoalForm
                        /*applicants={applicantInfo.data || ({} as Applicant)}*/
                        defaultGoal={isEditMode ? balance?.goal : 0}
                        applicants={(config.joinViewFeature && jointViewSelected) || !isEditMode ? config.applicants : notEnabledApplicants}
                        onSaveText={isEditMode ? 'Update' : 'Send Invite'}
                        handleApplicantChange={handleApplicantChange}
                        handleJointViewChange={handleJointViewChange}
                        editMode={isEditMode}
                        onCancel={closeModal}
                        loading={isLoading}
                        jointAccountEnabled={applicantInfo?.data?.jointAccountEnabled ?? false}
                        onSave={async (data: any) => {    
                            setLoading(true);   
                            
                            let saveApplicants = selectedApplicants;
                            if(isEditMode && (config.joinViewFeature && !jointViewSelected)){
                              saveApplicants = saveApplicants.concat(enabledApplicants);
                            }
                            await saveApplicantGoalInfo(saveApplicants, data.loanGoal, config.joinViewFeature && jointViewSelected);
                            setLoading(false);
                            applicantInfo.refetch();
                            setModalShow(false);

                            notificationRef.current?.success("Successfully enabled goal tracking");
                        }}
                    />
                </Dialog>

                <Dialog
                    show={turnOffmodalShow}
                    fullscreen={false}
                    className="applicant-goal-tracking-modal"
                    onHide={() => setTurnOffModalShow(false)}
                    title="Turn Off Goal Tracking"
                    size="lg"
                >
                  <TurnOffTackingForm 
                    content="You will not be charged for this client from next month. You will stop receiving loan updates for this client. Continue and turn it off?"
                    onCancel={() => setTurnOffModalShow(false)}
                    applicants={enabledApplicants}
                    handleApplicantChange={handleDeleteApplicantChange}
                    jointAccountEnabled={config.joinViewFeature && jointViewSelected}
                    loading={isLoading}
                    onSave={async () => {
                      try{
                        setLoading(true);

                        if((config.joinViewFeature && jointViewSelected) || deleteApplicants.length == 0){
                          await deleteApplicantGoalInfo(enabledApplicants, TrackingType.Savings);
                        }else{
                          await deleteApplicantGoalInfo(deleteApplicants, TrackingType.Savings);
                        }
                        //setEnableGoalTracking(false);
                        setDeleteApplicants([]);
                        applicantInfo.refetch();

                        notificationRef.current?.success("Successfully turned off goal tracking");
                      }catch(err){
                        //TODO: handle error
                        console.error(err);
                        notificationRef.current?.error("Something went wrong");
                       }
                       setTurnOffModalShow(false)
                       setLoading(false);                       
                    }}
                  />

                </Dialog>
            </div>
            <Notification ref={notificationRef}></Notification>
        </>
    );
};
