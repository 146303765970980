import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { getTheme } from "../../config";

type CalendarProps = {
  defaultValue?: any;
  control?: any;
  className: string;
  name: string;
  rules?: any;
  minDate?: Date;
};
const theme = getTheme();
const StyledDatePicker = styled(DatePicker)`
    border: 1px solid #98a7c8;
    border-radius: 4px;
    color: ${theme.secondaryColor};
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    padding: 10px 0.75rem;

    &:focus {
      box-shadow: none;
      border: 1px solid ${theme.secondaryColor};
    }

    &::placeholder {
      font-size: 12px;
      @include font-semibold;
      line-height: 19px;
      color: #98a7c8;
    }
`;

const CalendarInputController = ({
  defaultValue,
  control = null,
  name,
  rules = {},
  minDate
  
}: CalendarProps) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    rules={rules}
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <StyledDatePicker
        placeholderText="Select date"
        onChange={onChange}
        onBlur={onBlur}
        selected={value}
        dateFormat="dd/MM/yyyy"
        minDate={minDate}
      />
    )}
  />
);


export default CalendarInputController;