var _configs: ApplicantGoalTrackingOptions = {
  apiUrl: "https://localhost:7153/api/v1",
  token:
    "eyJhbGciOiJSUzI1NiIsImtpZCI6IjYxMEZBNjVFNTg1OTJFRjU5Q0E0RjFGMDBDMzU5RjA0NjQ3NzcwRTciLCJ0eXAiOiJKV1QiLCJ4NXQiOiJZUS1tWGxoWkx2V2NwUEh3RERXZkJHUjNjT2MifQ.eyJuYmYiOjE2ODU3NDg2NDIsImV4cCI6MTY4NTc1MjI0MiwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS1zZXJ2ZXIuc3RhZ2UuZWZmaS5jb20uYXUiLCJhdWQiOlsiaHR0cHM6Ly9pZGVudGl0eS1zZXJ2ZXIuc3RhZ2UuZWZmaS5jb20uYXUvcmVzb3VyY2VzIiwiRWZmaWVCcm9rZXJTZXJ2aWNlIiwiRWZmaWVEb2N1bWVudFNlcnZpY2UiLCJJZGVudGl0eVNlcnZlckFwaSIsImlkZW50aXR5X2FkbWluX2FwaSJdLCJjbGllbnRfaWQiOiJicm9rZXJhcHBfZGV2Iiwic3ViIjoiNGU5NzZkZTktNzQwOS00ZDEzLTg2OWYtMzg4Njg3NTYxODU2IiwiYXV0aF90aW1lIjoxNjg1NzQ4NjQxLCJpZHAiOiJsb2NhbCIsInJvbGUiOiJQbGF0Zm9ybSBBZG1pbiIsIm5hbWUiOiJkZXZlbG9wZXIrc3RhZ2VhZG1pbkBlZmZpLmNvbS5hdSIsImVtYWlsIjoiZGV2ZWxvcGVyK3N0YWdlYWRtaW5AZWZmaS5jb20uYXUiLCJQZXJtaXNzaW9ucyI6IlBsYXRmb3JtQWRtaW4uQWxsIiwiUm9sZVR5cGUiOiJOb25Ccm9rZXIiLCJzY29wZSI6WyJvcGVuaWQiLCJFZmZpZUJyb2tlclNlcnZpY2UiLCJFZmZpZURvY3VtZW50U2VydmljZSIsIklkZW50aXR5U2VydmVyQXBpIiwiaWRlbnRpdHlfYWRtaW5fYXBpIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdfQ.jOz6n0C2zDRIPFAEkr_RoP8YHet5vqOkkMVsuhTXvy_vPm3Ldnff6Go9uTJn98eqbxiTV-Quq_yxIfQIM7ZyScwyklR8oRGBSOQR_YZlQthRmjWfj_UT0wP0pSTcJ6AVAcgQhsx4PkK5tM5eglBwKPQI2ulg9xNURv6t1fCxCJ9ro3U_ahehH-cTBh6gwZVdMV2UCaGadajKBS9qtBCS7IaGDDY_FlKe5I9Xo9JyFhaBarA2i--dwzrJU3HVPj6C0fGco1KPITaV-vY2rDXShMKigUVNNwDkGrSp09-chsMySww3lp8yHNhUVwMkPx3E5l3WWYBHz6U20xLL5L60rw",
  applicants: [],
  leadId: "225949ae-0786-4e3c-b3a5-acb46ad7d004",
  locale: "en-AU",
  currency: "AUD",
  enterpriseName: "",
  joinViewFeature: false
};

var _context = {
  applicants: [] as ApplicantDto[]
};

var _theme: Theme = {
  primaryColor: "#14c7ce",
  secondaryColor: "#98a7c8"
};

export type ApplicantGoalTrackingOptions = {
  apiUrl: string;
  token: string;
  leadId: string;
  applicants: ApplicantDto[];
  accountType?: string;
  broker?: BrokerDto;
  locale?: string,
  currency?: string,
  enterpriseName?: string,
  joinViewFeature?: boolean,
  homeSaverOptions?: HomeSaverOptionsDto
}

export type HomeSaverOptionsDto = {
  programStartDate?: Date | string;
  programEndDate?: Date | string;
  startingBalance?: number;
  duration?: number;
  isEnabled?: boolean;
  applicationId?: string;
}

export type ApplicantDto = {
  applicantId: string;
  name: string;
  email: string;
  mobile?: string;
  isPrimaryApplicant: boolean;
  consumerPortalUrl?: string;
}

export type BrokerDto = {
  brokerId: string;
  name: string;
  email: string;
}
export type Theme = {
  primaryColor: string;
  secondaryColor: string;
};

export const setConfigs = (configs: ApplicantGoalTrackingOptions) => {
  if (configs) {
    _configs = configs;
  }
};

export const getConfigs = (): ApplicantGoalTrackingOptions => {
  return _configs;
};

export const getTheme = (): Theme => {
  return _theme;
};

export const setTheme = (theme: Theme) => {
  if (theme) {
    _theme = theme;
  }
};