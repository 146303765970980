import { useQuery } from "react-query";

import { axios } from "../../../lib/axios";
import { ExtractFnReturnType, QueryConfig } from "../../../lib/react-query";
import { Applicant, Balance, LoanAccount, TrackingType, UserGoalDetailResponse } from "../types";
import { ApplicantDto, getConfigs } from "../../../config";

const getApplicantId = (): string => {
  var applicantId = getConfigs().applicants.find(it => it.isPrimaryApplicant)?.applicantId;  
  if(!applicantId){
    applicantId = getConfigs().applicants[0].applicantId;
  }

  return applicantId;
}

export const getApplicantGoalInfo = (trackingType: TrackingType): Promise<UserGoalDetailResponse> => {
  return axios.get(`/goal-tracking/${getConfigs().leadId}?accountType=${TrackingType[trackingType].toLowerCase()}`);
};

export const getBalance = (): Promise<Balance> => {
  var primaryApplicantId = getApplicantId();
  return axios.get(`/open-banking/${primaryApplicantId}/accounts-balance?leadId=${getConfigs().leadId}&accountType=savings`);
};

export const saveApplicantGoalInfo = (applicants: ApplicantDto[], goal: number, jointAccountEnabled?: boolean): Promise<Applicant> => {
  var config = getConfigs();
  return axios.post(`/goal-tracking`, {
    goal,
    leadId: config.leadId,
    applicants,
    broker: config.broker,
    enterpriseName: config.enterpriseName,
    jointAccountEnabled: jointAccountEnabled
  });
};

export const ResendEmail = (applicants: ApplicantDto[], trackingType: TrackingType): Promise<Date> => {
  var config = getConfigs();
  return axios.post(`/goal-tracking/resend-email`, { applicants, bankAccountType: TrackingType[trackingType].toLowerCase(), broker: config.broker, enterpriseName: config.enterpriseName, leadId: config.leadId });
}

export const deleteApplicantGoalInfo = (applicants: ApplicantDto[], trackingType: TrackingType): Promise<Applicant> => {
  var leadId = getConfigs().leadId;
  var pairs = applicants.map(function (value) { return "applicantIds=" + encodeURIComponent(value.applicantId) });
  return axios.delete(`/goal-tracking?accountType=${TrackingType[trackingType].toLowerCase()}&leadId=${leadId}&${pairs.join("&")}`);
};

export const enableApplicantLoanInfo = (applicants: ApplicantDto[], jointAccountEnabled?: boolean): Promise<Applicant> => {
  var config = getConfigs();

  return axios.post(`/loan-tracking`, {
    applicants,
    jointAccountEnabled: jointAccountEnabled,
    leadId: config.leadId,
    broker: config.broker,
    enterpriseName: config.enterpriseName
  });
};

export const getApplicantLoanAccounts = ():Promise<LoanAccount[]> => {
  var primaryApplicantId = getApplicantId();
  var leadId = getConfigs().leadId;

  return axios.get(`/open-banking/${primaryApplicantId}/linked-loan-accounts?leadId=${leadId}`);
}

type QueryFnType = typeof getApplicantGoalInfo;

type ApplicantGoalInfoOptions = {
  config?: QueryConfig<QueryFnType>;
};

type ApplicantLoanAccountOptions = {
  config?: QueryConfig<typeof getApplicantLoanAccounts>;
};

export const useApplicantGoalInfo = (trackingType: TrackingType,{
  config,
}: ApplicantGoalInfoOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    enabled: getConfigs().applicants.length > 0,
    queryKey: ["getApplicantGoalInfo"],
    queryFn: () => getApplicantGoalInfo(trackingType),
  });
};

export const useApplicantLoanInfo = ({
  config,
}: ApplicantGoalInfoOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    enabled: getConfigs().applicants.length > 0,
    queryKey: ["getApplicantLoanInfo"],
    queryFn: () => getApplicantGoalInfo(TrackingType.Loan),
  });
};

export const useApplicantLoanAccounts = (status:string | undefined,{
  config,
}: ApplicantLoanAccountOptions = {}) => {  
  return useQuery<ExtractFnReturnType<typeof getApplicantLoanAccounts>>({    
    ...config,
    enabled: (status != undefined && (status.toLowerCase() == "registered")) ? true : false,
    queryKey: ["getApplicantLoanAccounts"],
    queryFn: () => getApplicantLoanAccounts(),
  });
};


