import { useForm } from "react-hook-form";
import { PrimaryButton, MultiSelect } from "../../../Elements";
import styled from 'styled-components';
import {ApplicantDto, getConfigs, getTheme} from "../../../config";
import { useEffect, useState } from "react";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import 'bootstrap-icons/font/bootstrap-icons.css';

const SendInviteButton = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.primaryColor} !important;
  border-color: ${(props) => props.theme.primaryColor} !important;
`;

export const LoanForm = ({
  applicants = [] as ApplicantDto[],
  //applicant = {} as Applicant,
  onSave = (() => {}) as any,
  onCancel = (() => {}) as any,
  loading = false as boolean,
  jointAccountEnabled = false as boolean,
  handleApplicantChange = ((selectedOption: any) => {}),
  handleJointViewChange = ((selectedOption: any) => {})
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    //values: applicant,
  });

  const theme = getTheme();
  const configs = getConfigs();

  const [saveDisabled, setSaveDisabled] =  useState(false);
  const [checked, setChecked] = useState(jointAccountEnabled);
  const [tooltipShow, setTooltipShow] = useState(false);

  const options = applicants.map((item: ApplicantDto) => {
    return {value: item.applicantId, label: item.name};
  }); 

  useEffect(() => {
    if(configs.joinViewFeature && checked){
      setSaveDisabled(false);
    }
    else if(applicants && applicants.length > 1){
      setSaveDisabled(true);
    }  
  }, [applicants, checked])

  const applicantSelected = (selectedOption: any[]) => {
    if(configs.joinViewFeature && checked){
      setSaveDisabled(false);
    }
    else if(applicants && applicants.length > 1 ){
      if(selectedOption.length > 0){
        setSaveDisabled(false);
      }else{
        setSaveDisabled(true);
      }
    }

    handleApplicantChange(selectedOption);
  }

  const checkBoxChanged = (value: boolean) => {
    if(configs.joinViewFeature){
      setChecked(value);
      handleJointViewChange(value);
    }
  }

  const onSubmit = onSave;
  return (
      <div className="container">
        <form className="filter-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row align-items-center">
          {(applicants && applicants.length > 1) ? 
          <>
            <div className="row" style={{ paddingRight: '0', width: '100%' }}>
              <div className="col-md-12" style={{ textAlign: 'left', marginBottom: '10px', marginTop: '10px' }}>
                <label style={{ fontSize: 'small', fontWeight: 'bold', color: '#8D95A6' }}>Send Request To</label>
              </div>
              <div className="col-md-12" style={{ paddingRight: '0' }}>
                <MultiSelect options={options} isMulti={true} handleChange={applicantSelected} isDisabled={(configs.joinViewFeature ?? false) && checked} selectedValues={configs.joinViewFeature && checked ? options: undefined}></MultiSelect>
              </div>
            </div>
            {configs.joinViewFeature && <div className="row" 
                 style={{ 
                    paddingRight: '0', 
                    width: '100%',
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start' }}>
              <div className="col-md-12" style={{ textAlign: 'left', marginTop: '20px' }}>
                <FormCheckInput
                  onChange={(event) => checkBoxChanged(event.target.checked)}
                  checked={checked}/>
                <label htmlFor="jointAccountEnabled" style={{ marginLeft: '10px' }}>Joint View</label>
                <i style={{
                    cursor: 'pointer',
                    color: '#000000',
                    marginLeft: "10px",
                  }} onClick={() => setTooltipShow(!tooltipShow)} className="bi bi-info-circle"/>
                <div 
                  style={{
                    display: (tooltipShow ? 'block' : 'none'),
                    position: 'absolute',
                    bottom: '-100px',
                    left: '120px',
                    backgroundColor: '#000',
                    color: '#fff',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '300px',
                    minWidth: '250px',
                    zIndex: 9999}}>
                  <div 
                    style={{
                      content: '',
                      position: 'absolute',
                      top: '-10px',
                      left: '12px',
                      borderWidth: '5px',
                      borderStyle: 'solid',
                      borderColor: 'transparent transparent #000000 transparent'
                    }}/>
                    Selecting <b>Joint View</b> will allow invited applicants to view each other's accounts in a single view.
                </div>
              </div>
            </div>}
          </>
          : null }
        </div>
        <div className="row" style={{ marginTop: '40px' }}>
          <div className="col-12 text-end">
            <button
                type="button"
                className="btn btn-sm me-2"
                onClick={onCancel}
                style={{ backgroundColor: 'white', color: 'red', borderColor: 'red' }}
            >
              Cancel
            </button>
            <PrimaryButton
                type="submit"
                disabled={saveDisabled}
                loading={loading ? 'true' : 'false'}
                className="btn btn-primary btn-sm"
                style={{ backgroundColor: theme.primaryColor, borderColor: theme.primaryColor }}
            >
              Send Invite
            </PrimaryButton>
          </div>
        </div>
        </form>
        

      </div>
  );
};
