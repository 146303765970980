import React from 'react';
import { getTheme } from "../../config";
import styled from "styled-components";

export const ToggleButton = (props: any) => {
  const theme = getTheme();

  const ToggleLabel = styled.label`
    --width: 4rem;
    --height: calc(var(--width) / 2.5);

    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
  `;

  const SliderSpan = styled.span`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--height);   
        background-color: #98a7c8 !important;
        border-color: #98a7c8 !important;
        transition: all 0.4s ease-in-out;
        &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: calc(var(--height) / 1.2);
            height: calc(var(--height) / 1.2);
            border-radius: calc(var(--height) / 2);
            background-color: #fff;
            transition: all 0.4s ease-in-out;
          }
          &::after {
            content: "OFF";
            font-size: 12px;
            font-weight: 600;
            position: absolute;
            z-index: 1;
            right: 1rem;
            top: 0.25rem;
            color: #fff;
          }
  `;

  const SliderInput = styled.input`
        display: none;
        &:checked + ${SliderSpan} {
            border-color: #39c38e !important;
            background-color: #39c38e !important;
        }
        
        &:checked + ${SliderSpan}::before {
            transform: translateX(calc(var(--width) - var(--height)));
        }
        &:checked + ${SliderSpan}::after {
          content: "ON";
          font-size: 12px;
          font-weight: 600;
          position: absolute;
          z-index: 1;
          left: 1rem;
          top: 0.25rem;
          color: #fff;
        }
  `;

  return (
    <>
        <ToggleLabel>
            <SliderInput
                type="checkbox"
                {...props}
            />
            <SliderSpan></SliderSpan>
        </ToggleLabel>
    </>
  );
};
  
