import React from "react";
import * as ReactDOM from "react-dom/client";
import reactToWebComponent from "react-to-webcomponent";
import App, {LoanTrackingApp} from "./App";
//import './index.css';

const goalTracking = reactToWebComponent(App, React, ReactDOM, {
  props: {
    options: "json",
    theme: "json"
  },
  shadow: "open"
});
customElements.define("goal-tracking", goalTracking);

const loanTracking = reactToWebComponent(LoanTrackingApp, React, ReactDOM, {
  props: {
    options: "json",
    theme: "json"
  },
  shadow: "open"
});

customElements.define("loan-tracking", loanTracking);
