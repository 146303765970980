import { AppProvider } from "./providers/app";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.scss";
// eslint-disable-next-line
import bootstrap from "!!raw-loader!bootstrap/dist/css/bootstrap.min.css";

import "react-datepicker/dist/react-datepicker.css";
// eslint-disable-next-line
import datePickerCss from "!!raw-loader!react-datepicker/dist/react-datepicker.css";

// eslint-disable-next-line
import indexCss from "!!raw-loader!./index.css";

// eslint-disable-next-line
import bootstrapIcons from "!!raw-loader!!bootstrap-icons/font/bootstrap-icons.min.css";

import { ApplicantGoalTrackingOptions, setConfigs, setTheme } from "./config";
import { Landing } from "./features/landing";
import { StyleSheetManager } from "styled-components";
import { ApplicantLoanTracking } from "./features/applicant/components/ApplicantLoanTracking";


export function App({ options, theme, context }: any) {
  setTheme(theme);
  setConfigs(options as ApplicantGoalTrackingOptions);

  var head = document.head || document.getElementsByTagName('head')[0], style = document.createElement('style');

  head.appendChild(style);

  style.type = 'text/css';
  style.appendChild(document.createTextNode(indexCss));

  var shadowRoot = document.getElementsByTagName('goal-tracking')[0].shadowRoot;
  var elem = shadowRoot?.appendChild(document.createElement('div'));

  return (
    <StyleSheetManager target={elem}>
      <style>{bootstrap}</style>
      <style>{datePickerCss}</style>
      <style>{bootstrapIcons}</style>
      <AppProvider>
        <Landing />
      </AppProvider>
    </StyleSheetManager>
  );
}

export function LoanTrackingApp({ options, theme }: any) {
  setTheme(theme);
  setConfigs(options as ApplicantGoalTrackingOptions);

  var head = document.head || document.getElementsByTagName('head')[0], style = document.createElement('style');
  head.appendChild(style);

  style.type = 'text/css';
  style.appendChild(document.createTextNode(indexCss));

  var shadowRoot = document.getElementsByTagName('loan-tracking')[0].shadowRoot;
  var elem = shadowRoot?.appendChild(document.createElement('div'));

  return (
    <StyleSheetManager target={elem}>
      <style>{bootstrap}</style>
      <style>{bootstrapIcons}</style>
      <AppProvider>
        <ApplicantLoanTracking />
      </AppProvider>
    </StyleSheetManager>
  );
}

export default App;
