import { useForm } from "react-hook-form";
import { NumericFormatController, PrimaryButton, MultiSelect } from "../../../Elements";
import styled from 'styled-components';
import {getTheme, ApplicantDto, getConfigs} from "../../../config";
import { useEffect, useState } from "react";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import 'bootstrap-icons/font/bootstrap-icons.css';


const SendInviteButton = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.primaryColor} !important;
  border-color: ${(props) => props.theme.primaryColor} !important;
`;


export const GoalForm = ({
  //applicant = {} as Applicant,
  defaultGoal  = 0 as number,
  applicants = [] as ApplicantDto[],
  onSave = (() => {}) as any,
  onCancel = (() => {}) as any,  
  onSaveText = '' as string,
  loading = false as boolean,
  editMode = false as boolean,
  jointAccountEnabled = false as boolean,
  handleApplicantChange = ((selectedOption: any) => {}),
  handleJointViewChange = ((selectedOption: any) => {})
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    //values: applicant,
  });

  const theme = getTheme(); 
  const config = getConfigs();

  const [saveDisabled, setSaveDisabled] =  useState(false);
  const [checked, setChecked] = useState(jointAccountEnabled);
  const [tooltipShow, setTooltipShow] = useState(false);


  const options = applicants.map((item: ApplicantDto) => {
    return {value: item.applicantId, label: item.name};
  });  

  useEffect(() => {
    if(config.joinViewFeature && checked){
      setSaveDisabled(false);
    }
    else if(applicants && applicants.length > 1 && !editMode){
      setSaveDisabled(true);
    }  
  }, [applicants, editMode, checked])
  
  const applicantSelected = (selectedOption: any[]) => {

    if(config.joinViewFeature && checked){
      setSaveDisabled(false);
    }
    else if(applicants && applicants.length > 1 && !editMode){
      if(selectedOption.length > 0){
        setSaveDisabled(false);
      }else{
        setSaveDisabled(true);
      }
    }
    handleApplicantChange(selectedOption);
  }

  const checkBoxChanged = (value: boolean) => {
    if(config.joinViewFeature){
      setChecked(value);
      handleJointViewChange(value);
    }
  }
  const onSubmit = onSave;
  return (
      <form className="filter-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6 d-flex">
            <label className="form-label fw-semibold" style={{ alignSelf: 'center' }}>Savings Goal</label>
            <hr style={{
              flex: 1,
              borderColor: 'black',
              borderStyle: 'dashed',
              marginLeft: '10px',
            }} />
          </div>         
          <div className="col-md-6">
              <div className="row align-items-center">
                <div className="col-md-12 input-group">
                  <span className="input-group-text fw-semibold" style={{ backgroundColor: '#EFF4FF' }}>$</span>
                  <NumericFormatController
                      defaultValue={defaultGoal}
                      className="form-control"
                      control={control}
                      name="loanGoal"
                      rules={{ required: true }}
                  />
                </div>
                <div className="col-md-12 text-warning text-danger text-end" style={{ fontSize: '12px' }}>
                  {errors.loanGoal?.type === "required" && (
                      <span>This is required!</span>
                  )}
                </div>
              </div>            
          </div>
        </div>
        <div className="row">
          {(applicants && (((editMode || (config.joinViewFeature && checked)) && applicants.length > 0 ) || applicants.length > 1)) ? 
            <div className="row" style={{ paddingRight: '0', width: '100%' }}>
              <div className="col-md-12" style={{ textAlign: 'left', marginBottom: '10px', marginTop: '20px' }}>
                <label style={{ fontSize: 'small', fontWeight: 600, color: '#8D95A6' }}>Request Sent to</label>
              </div>
              <div className="col-md-12" style={{ paddingRight: '0' }}>
                  <MultiSelect options={options} isMulti={true} handleChange={applicantSelected} isDisabled={(config.joinViewFeature ?? false) && checked} selectedValues={config.joinViewFeature && checked ? options: undefined}></MultiSelect>
              </div>
            </div>
            : null}
        </div>
        {config.joinViewFeature && 
          <div className="row" style={{ marginTop: '12px' }}>
            <div className="col">
              <FormCheckInput
                  onChange={(event) => checkBoxChanged(event.target.checked)}
                  checked={checked}
              />
              <label htmlFor="jointAccountEnabled" style={{ marginLeft: '10px' }}>Joint View</label>
              <i style={{
                  cursor: 'pointer',
                  color: '#000000',
                  marginLeft: "10px",
                }} onClick={() => setTooltipShow(!tooltipShow)} className="bi bi-info-circle"/>
              <div 
                style={{
                  display: (tooltipShow ? 'block' : 'none'),
                  position: 'absolute',
                  left: '110px',
                  backgroundColor: '#000',
                  color: '#fff',
                  border: '1px solid #ccc',
                  padding: '10px',
                  borderRadius: '5px',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  maxWidth: '300px',
                  minWidth: '250px',
                  zIndex: 9999}}>
                <div 
                  style={{
                    content: '',
                    position: 'absolute',
                    top: '-10px',
                    left: '12px',
                    borderWidth: '5px',
                    borderStyle: 'solid',
                    borderColor: 'transparent transparent #000000 transparent'
                  }}/>
                  Selecting <b>Joint View</b> will allow invited applicants to view each other's accounts in a single view.
              </div>
            </div>
          </div>
        }
        <div className="row" style={{ marginTop: '40px' }}>
          <div className="col-12 text-end">
            <button
                type="button"
                className="btn btn-sm me-2"
                onClick={onCancel}
                style={{ backgroundColor: 'white', color: 'red', borderColor: 'red' }}
            >
              Cancel
            </button> 
            <PrimaryButton
                type="submit"
                disabled={saveDisabled}
                loading={loading ? 'true' : 'false'}
                className="btn btn-primary btn-sm"
                style={{ backgroundColor: theme.primaryColor, borderColor: theme.primaryColor }}
            >
              {onSaveText}
            </PrimaryButton>
          </div>
        </div>
      </form>
  );
};
