import { useEffect, useRef, useState } from "react";
import { toCurrencyFormat } from "../../../utils/format";
import { Spinner, PrimaryButtonOutline, Dialog, ToggleButton, Card, NotificationConfig, Notification } from "../../../Elements";
import {
    useApplicantLoanAccounts,
    enableApplicantLoanInfo,
    deleteApplicantGoalInfo,
    ResendEmail,
    useApplicantLoanInfo,
} from "../api/getApplicantGoalInfo";
import { LoanAccount, TrackingType } from "../types";
import { LoanForm } from "./LoanForm";
import styled from "styled-components";
import { ReactComponent as Idea } from '../../../assets/images/idea.svg';
import { ApplicantDto, getConfigs } from "../../../config";
import { TurnOffTackingForm } from "./TurnOffTrackingForm";
import Moment from "react-moment";

// eslint-disable-next-line import/no-webpack-loader-syntax
import toastifyCss from '!!raw-loader!react-toastify/dist/ReactToastify.css';

const CardTextKey = styled.p`
  justify-content: space-between;
  display: flex;
  font-size: small;
  font-weight: bold;
  color: #8792A9;
  margin-bottom: 0.2rem;
`;

const CardTextValue = styled.p`
  font-size: small;
  color: #33366A;
  margin-bottom: 0.2rem;
`;

const CardTextBalance = styled.p`
  font-size: large;
  color: #33366A;
  margin-bottom: 0.2rem;
  font-weight: bold;
`;

const CardAccountName = styled.p`
  font-size: large;
  color: #24275E;
  margin-bottom: 0.7rem;
  font-weight: bold;
`;

const TrackingLabel = styled.label`
  font-weight: bold;
`;

const TooltipContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

const InfoIcon = styled.i`
  cursor: pointer;
  color: #000000;
`;

const Tooltip = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  bottom: -75px;
  left: -10px;
  background-color: #000;
  color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 9999;
  
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 12px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000000 transparent;
  }
`;

export const ApplicantLoanTracking = () => {
    const applicantInfo = useApplicantLoanInfo();
    const loanAccountsQuery = useApplicantLoanAccounts(applicantInfo?.data?.status);
    const [modalShow, setModalShow] = useState(false);
    const [loanAccounts, setLoanAccounts] = useState<LoanAccount[]>();
    const [isLoanTrackingEnabled, setEnableLoanTracking] = useState(false);
    const [selectedApplicants, setSelectedApplicants] = useState([] as ApplicantDto[]);
    const [turnOffmodalShow, setTurnOffModalShow] = useState(false);
    const [tooltipShow, setTooltipShow] = useState(false);
    const [lastSentTooltipShow, setLastSentTooltipShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [resendIsLoading, setResendIsLoading] = useState(false);
    const [deleteApplicants, setDeleteApplicants] = useState([] as ApplicantDto[]);
    const [enabledApplicants, setEnabledApplicants] =  useState([] as ApplicantDto[]);
    const [jointViewSelected, setJointViewSelected] = useState(false);

    const notificationRef = useRef<NotificationConfig>(null);


    const config = getConfigs();

    useEffect(() => {
        if(config.joinViewFeature && applicantInfo?.data){
          setJointViewSelected(applicantInfo?.data?.jointAccountEnabled ?? false);
        }
        
        if(config.applicants && config.applicants.length === 1){
            setSelectedApplicants(config.applicants);
            setDeleteApplicants(config.applicants);
        }

        if(applicantInfo?.data && applicantInfo?.data?.status === "Registered"){
            setEnableLoanTracking(true);

            let enabledApplicants = config.applicants.filter(it => {
                return applicantInfo?.data.enabledApplicants.some(x => {
                  return x.referenceId === it.applicantId;
                })
              });

              setEnabledApplicants(enabledApplicants);

          }else{
            setEnableLoanTracking(false);
          }

        if(loanAccountsQuery.data){
            setLoanAccounts(loanAccountsQuery.data);
        }

        window.addEventListener('new-applicant',(event) =>{ applicantInfo.refetch() });
        window.addEventListener('delete-applicant',(event) =>{ applicantInfo.refetch() });
   
        return () => {
          window.removeEventListener('delete-applicant',(event) =>{ applicantInfo.refetch() });
          window.removeEventListener('new-applicant', (event) =>{ applicantInfo.refetch() });
        }; 
    },[applicantInfo, loanAccountsQuery?.data, config.applicants, config.joinViewFeature]);
    
    useEffect(() => {
      if(config.joinViewFeature){
        setSelectedApplicants(config.applicants);
      }
    }, [jointViewSelected, config.applicants, config.joinViewFeature]);

    if(applicantInfo.isLoading || (!loanAccountsQuery.isIdle && loanAccountsQuery.isLoading)){
        return (
            <div className="d-flex justify-content-end">
              <Spinner />
            </div>
          );
    }

    const linkLoanAccounts = () => {
        if(config.applicants && config.applicants.length > 0){
            window.open(config.applicants[0].consumerPortalUrl, "_blank", "noreferrer");
        }
    }

    const ZebraColoredCards = () => {
        return (
            <>
                {(loanAccounts && loanAccounts.length > 0) ? loanAccounts!.map((data, index) => {
                    const backgroundColor = index % 2 === 0 ? "#f2f3f5" : "#FFFFFF";
                    return (
                        <Card key={index} background={backgroundColor}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4" style={{}}>
                                        <CardAccountName>{data.accountName}</CardAccountName>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <CardTextKey>Loan Balance</CardTextKey>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: '0.2rem' }}>
                                    <div className="col-md-4">
                                        <CardTextBalance>{data.loanBalance !== null ? toCurrencyFormat(data.loanBalance).split('.')[0] : "Not set"}</CardTextBalance>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <CardTextKey>Loan Account No:
                                            <CardTextValue>{data.accountNumber}</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Lender Name:
                                            <CardTextValue>{data.lenderName}</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Repayment Frequency:
                                            <CardTextValue>{data.repaymentFrequency}</CardTextValue>
                                        </CardTextKey>
                                    </div>
                                    <div className="col-md-3">
                                        <CardTextKey>Loan Name:
                                            <CardTextValue>{data.loanName}</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Origination Date:
                                            <CardTextValue>{data.originationDate}</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Next Repayment Date:
                                            <CardTextValue>{data.nextRepaymentDate}</CardTextValue>
                                        </CardTextKey>
                                    </div>
                                    <div className="col-md-3">
                                        <CardTextKey>Current Interest Rate:
                                            <CardTextValue>{data.currentInterestRate}%</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Term Remaining:
                                            <CardTextValue>{data.termRemaining}</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Minimum Repayment Amount:
                                            <CardTextValue>{data.minimumRepaymentAmount !== null ? toCurrencyFormat(data.minimumRepaymentAmount).split('.')[0] : "Unavailable"}</CardTextValue>
                                        </CardTextKey>
                                    </div>
                                    <div className="col-md-3">
                                        <CardTextKey>Repayment Type:
                                            <CardTextValue>{data.sourceLoanRepaymentType && data.sourceLoanRepaymentType !== "0" ? data.sourceLoanRepaymentType : "Unavailable"}</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Max Redraw:
                                            <CardTextValue>{data.maxRedraw !== null ? toCurrencyFormat(data.maxRedraw.amount ?? 0).split('.')[0] : "Unavailable"}</CardTextValue>
                                        </CardTextKey>
                                        <CardTextKey>Offset Enabled:
                                            <CardTextValue>{data.sourceLoanOffsetEnabled !== null ? (data.sourceLoanOffsetEnabled ? "Yes" : "No") : "Unavailable"}</CardTextValue>
                                        </CardTextKey>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    );
                }) : 
                (isLoanTrackingEnabled ? <div>
                <div className="mb-3" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FEF7E8', padding: '15px', borderRadius: '5px' }}>
                   <Idea />
                    <div className="ms-3" style={{ color: '#8E95A7' }}>
                        Unlock the capabilities of open banking and take control of your loan journey. Seamlessly track every aspect of your loan's progrss with confidence and ease. Stay informed, stay empowered.
                    </div>
                </div>
                <PrimaryButtonOutline onClick={linkLoanAccounts}>
                    Link Loan Accounts
                </PrimaryButtonOutline>
                </div> : null )
                }
            </>
        );
    };

    const handleApplicantChange = (selectedOption: any[]) => {
        var filteredApplicants = config.applicants.filter(it => {
          return selectedOption.some(x => {
            return x.value === it.applicantId;
          })
        });
        
        setSelectedApplicants(filteredApplicants);
     }
     
    const handleJointViewChange = (value: boolean) => {
      if(config.joinViewFeature){
        setJointViewSelected(value);
      }
    }

     const handleDeleteApplicantChange = (selectedOption: any[]) => {
        let filteredApplicants = config.applicants.filter(it => {
          return selectedOption.some(x => {
            return x.value === it.applicantId;
          })
        });
        
        setDeleteApplicants(filteredApplicants);
     }

    const enableLoanTracking = async () => {
        if (!isLoanTrackingEnabled && config.applicants.length > 0) {
            setModalShow(true);
        }else{
            setTurnOffModalShow(true);
        }        
   }

   const resendEmail = () => {
    setResendIsLoading(true);
    ResendEmail(selectedApplicants, TrackingType.Loan).then((sentTime: Date) => 
    {
      notificationRef.current?.success("Successfully sent email");
      if(applicantInfo?.data){
        applicantInfo.data.lastEmailSentTime = sentTime;
      }
      setResendIsLoading(false);
    }
    , err => {
      notificationRef.current?.error("Seomthing went wrong");
      setResendIsLoading(false);
    });
 }

   const loanDisabledTooltip = `You can track loans of this lead using open banking and be notified once the loan rate is changed.
   Once the lead connects their loans accounts and the track loans toggle remains ON, $1 plus GST will be charged every month. Switch off the Toggle 
   anytime to stop the tracking and billing for this lead.`;

   const loanEnabledTooltip = `Please note that you will be charged $1 plus GST per active client per month.`;

    return (  
        <>
            <style>{toastifyCss}</style>
            <div id="applicant-loan-tracking">
                <div className="d-flex flex-direction-row">
                    <ToggleButton onChange={enableLoanTracking} checked={isLoanTrackingEnabled}></ToggleButton>
                    <TrackingLabel className="ms-3">Track Loan</TrackingLabel>
                    <TooltipContainer>
                        <InfoIcon tabIndex={0} className="bi bi-info-circle" onClick={() => setTooltipShow(true)} onBlur={() => setTooltipShow(false)}></InfoIcon>
                        <Tooltip show={tooltipShow}>{isLoanTrackingEnabled ? loanDisabledTooltip : loanEnabledTooltip}</Tooltip>
                    </TooltipContainer>
                </div>
                {isLoanTrackingEnabled ? 
                <div className="d-flex flex-direction-row mt-3">
                  <PrimaryButtonOutline loading={resendIsLoading ? 'true' : 'false'} onClick={resendEmail}>Resend Email/SMS</PrimaryButtonOutline>
                  <TooltipContainer>
                        <InfoIcon tabIndex={1} className="bi bi-info-circle" onClick={() => setLastSentTooltipShow(true)} onBlur={() => setLastSentTooltipShow(false)}></InfoIcon>
                        <Tooltip show={lastSentTooltipShow}>
                            Last Sent{' '} 
                            <Moment format="LT">{applicantInfo?.data?.lastEmailSentTime}</Moment> on{' '} 
                            <Moment format="D MMM YYYY">{applicantInfo?.data?.lastEmailSentTime}</Moment>
                        </Tooltip> 
                    </TooltipContainer>
                </div>  : null }
               
                <br />
                {isLoanTrackingEnabled ? <ZebraColoredCards /> : null }
                <br />
                <Dialog
                    show={modalShow}
                    fullscreen={false}
                    className="applicant-goal-tracking-modal"
                    onHide={() => setModalShow(false)}
                    title="Request to link loan accounts"
                >
                   <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FEF7E8', padding: '15px',
                      borderRadius: '5px' }}>
                   <Idea />
                    <div style={{ marginLeft: '10px', color: '#8E95A7' }}>
                        An email will be sent to this applicant with a unique link to get started with linking loan accounts.
                    </div>
                    </div>
                    <LoanForm
                        applicants={config.applicants}
                        handleApplicantChange={handleApplicantChange}
                        onCancel={() => setModalShow(false)}
                        loading={isLoading}
                        jointAccountEnabled={applicantInfo?.data?.jointAccountEnabled ?? false}
                        handleJointViewChange={handleJointViewChange}
                        onSave={async (data: any) => {
                            setLoading(true);
                            await enableApplicantLoanInfo(selectedApplicants, config.joinViewFeature && jointViewSelected);
                            setLoading(false);
                            applicantInfo.refetch();
                            setModalShow(false);
                            notificationRef.current?.success("Successfully enabled loan tracking");
                        }}
                    />
                </Dialog>

                <Dialog
                    show={turnOffmodalShow}
                    fullscreen={false}
                    className="applicant-goal-tracking-modal"
                    onHide={() => setTurnOffModalShow(false)}                    
                    title="Turn Off Loan Tracking"
                    size="lg"
                >
                  <TurnOffTackingForm 
                    content="You will not be charged for this client from next month. You will stop receiving goal updates for this client. Continue and turn it off?"
                    onCancel={() => setTurnOffModalShow(false)}
                    applicants={enabledApplicants}
                    handleApplicantChange={handleDeleteApplicantChange}
                    jointAccountEnabled={config.joinViewFeature && jointViewSelected}
                    loading={isLoading}
                    onSave={async () => {                       
                      try{
                        setLoading(true);
                        
                        if((config.joinViewFeature && jointViewSelected) || deleteApplicants.length === 0){
                            await deleteApplicantGoalInfo(enabledApplicants, TrackingType.Loan);
                          }else{
                            await deleteApplicantGoalInfo(deleteApplicants, TrackingType.Loan);
                          }
                        //setEnableLoanTracking(false);
                        setDeleteApplicants([]);
                        applicantInfo.refetch();

                        notificationRef.current?.success("Successfully turned off loan tracking");
                      }catch(err){
                        notificationRef.current?.error("Something went wrong");
                      }
                      setTurnOffModalShow(false);
                      setLoading(false);
                    }}
                  />                    
                </Dialog>
            </div>
            <Notification ref={notificationRef}></Notification>
        </>
    );
};

