import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { parseISO, format } from "date-fns";

import { getTheme, HomeSaverOptionsDto } from "../../../config";
import {
  NumericFormatController,
  PrimaryButton,
} from "../../../Elements";
import CalendarInputController from "../../../Elements/Calendar";

const theme = getTheme();
const ActionButtonRow = styled.div`
  text-align: right !important;\
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	
	.btn-clear {
    background-color: transparent;
    padding: 5px 10px;
    border: 0;
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
    color: ${theme.primaryColor};

    &:not(:disabled):not(.disabled):active {
      color: #EA6F49;
      background-color: transparent;
      border-color: transparent;
      opacity: 0.7;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }
`;

const CustomLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #677491;
  margin-right: 1.5rem;
`;

const StyledPrefix = styled.span`
  font-weight: 600 !important;
  border: 1px solid #98a7c8 !important;
  background-color: #EFF4FF;
`;

const StyledNumericInput = styled(NumericFormatController)`
    border: 1px solid #98a7c8 !important;
    color: ${theme.secondaryColor};
    font-weight: 600;
    font-size: 14px !important;
    height: 40px;
    max-width: 200px;
    padding: 10px 0.75rem !important;

    &:focus {
      box-shadow: none;
      border: 1px solid ${theme.secondaryColor} !important;
    }

    &::placeholder {
      font-size: 12px;
      @include font-semibold;
      line-height: 19px;
      color: #98a7c8;
    }
`;

interface Props {
  options: HomeSaverOptionsDto;
}

const getFormmattedDate = (date: string | Date | undefined) => {
  var dateToFormat = date ? new Date(date) : new Date();
  var parsedDate = parseISO(dateToFormat.toISOString());
  var formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss")

  return formattedDate;
}

const HomeSaverProgramSettings = ({ options: values }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
    setValue
  } = useForm({
    values,
  });

  const [formCleared, setFormCleared] = useState(false);

  useEffect(() => {
    const startDate = new Date(watch('programStartDate') || '');
    const endDate = new Date(watch('programEndDate') || '');

    if (endDate < startDate) {
      setValue("programEndDate", startDate);
    }

  }, [watch('programStartDate'), watch('programEndDate')]);

  const theme = getTheme();

  const onSubmit = async (data: HomeSaverOptionsDto) => {
    var formattedStartDate = getFormmattedDate(data.programStartDate);
    var formattedEndDate = getFormmattedDate(data.programEndDate);

    if (values.applicationId) {
      let customEvent = new CustomEvent("handleSaveHomeSaverProgram", {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
          applicationId: values.applicationId,
          duration: data.duration || 0,
          startingBalance: data.startingBalance || 0,
          programStartDate: formattedStartDate,
          programEndDate: formattedEndDate,
        },
      });
      window.dispatchEvent(customEvent);
    }
    reset(data);
  };

  const handleClear = () => {
    if (formCleared) {
      reset(values);
      setFormCleared(false);
    } else {
      reset({ startingBalance: 0, programStartDate: new Date(), programEndDate: new Date() });
      setFormCleared(true);
    }
  };

  return (
    <form
      className="filter-form row d-flex flex-col"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-auto">
        <div className="d-flex flex-col">
          <CustomLabel>Home Saver Starting Balance</CustomLabel>
          <div className="input-group">
            <StyledPrefix className="input-group-text">$</StyledPrefix>
            <StyledNumericInput
              defaultValue={values.startingBalance || 0}
              className="form-control"
              control={control}
              name="startingBalance"
              rules={{ required: true }}
            />
          </div>
        </div>
        <div className="invalid-feedback">
          <span>{errors.startingBalance?.message}</span>
        </div>
      </div>
      <div className="col-auto">
        <div className="d-flex flex-col">
          <CustomLabel>Home Saver Program Start Date</CustomLabel>
          <CalendarInputController
            defaultValue={values.programStartDate}
            className="field-input"
            control={control}
            name="programStartDate"
          />
        </div>
        <div className="invalid-feedback">
          <span>{errors.programStartDate?.message}</span>
        </div>
      </div>
      <div className="col-auto">
        <div className="d-flex flex-col">
          <CustomLabel>Home Saver Program End Date</CustomLabel>
          <CalendarInputController
            defaultValue={values.programEndDate}
            className="field-input"
            minDate={watch("programStartDate") ? new Date(watch("programStartDate") || '') : undefined}
            control={control}
            name="programEndDate"
          />
        </div>
        <div className="invalid-feedback">
          <span>{errors.programEndDate?.message}</span>
        </div>
      </div>
      <div className="d-flex col-auto align-items-center">
        <ActionButtonRow>
          <button
            type="button"
            className="btn btn-sm me-2 btn-clear"
            onClick={handleClear}
          >
            {formCleared ? "Reset" : "Clear"}
          </button>
          {isDirty ? (
            <PrimaryButton
              type="submit"
              disabled={!isValid}
              className="btn btn-primary"
              style={{
                backgroundColor: theme.primaryColor,
                borderColor: theme.primaryColor,
              }}
            >
              Update
            </PrimaryButton>
          ) : null}
        </ActionButtonRow>
      </div>
    </form>
  );
};

export default HomeSaverProgramSettings;
