import chroma from 'chroma-js';
import Select, { StylesConfig } from 'react-select';
import { getTheme } from "../../config";

export interface MultiSelectOption {
  label: string;
  value:string;
  isFocused ?: boolean;
}

export interface IMultiSelect {
  options: MultiSelectOption[];
  selectedValues? :  MultiSelectOption[];
  isMulti: boolean;
  isDisabled: boolean;
  handleChange?: (selectedOption : any) => void
}

export const MultiSelect = (props: IMultiSelect) => {
  const theme = getTheme();

  const colourStyles: StylesConfig<MultiSelectOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, {isFocused }) => {
      const color = chroma('#638CFF');
      return {
        ...styles,     
        color: isFocused ? '#fff' : '#24275e',
        backgroundColor: isFocused ? color.alpha(0.8).css() : '#fff',
        ':active': {
          ...styles[':active'],
          backgroundColor: color.alpha(0.3).css(),
          color: 'white'
        },
        ':hover': {
          ...styles[':hover'],
          backgroundColor: color.alpha(0.8).css(),
          color: 'white'
        }
      };
    },
    multiValue: (styles) => {
      const color = props.isDisabled ? chroma('#6d7178'): chroma('#638CFF');
      return {
        ...styles,
        backgroundColor: color.alpha(1.0).css(),
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#ffffff',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: '#ffff',
      ':hover': {
        backgroundColor: '#5477d3',
        color: 'white',
      },
    }),
  };

  return (<Select options={props.options} isMulti={props.isMulti} styles={colourStyles} onChange={props.handleChange} isDisabled={props.isDisabled} value={props.selectedValues}/>)
};
  
